import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { Section } from 'components/organisms';
import {
      useMediaQuery,
      Grid,
      Typography,
      Button,
      Paper,
    } from '@material-ui/core';

const awsSvg = "/images/Amazon_Web_Services_Logo.svg";
const azureSvg = "/images/Azure.svg";
const googleSvg = "/images/Google-cloud-platform-cropped.svg";

const GetProviderImage = props => {
  const { provider, isMd, classes } = props;
  return (
    <Image
        src={provider === 'aws' ? awsSvg : provider === 'azure' ? azureSvg : provider === 'google' ? googleSvg : ''}
        alt={provider === 'aws' ? 'aws' : provider === 'azure' ? 'azure' : provider === 'google' ? 'google' : ''}
        className={isMd ? classes.logoImg : classes.logoImgSm}
        lazy={false}
      />
  );
};  

const GetQuotesButton = props => {
  const { classes, provider, location } = props;
  let href = '/get-a-quote';
  switch(provider) {
    case 'aws':
      href = `/aws-s3-savings/?location=${location}`;
      break;
    case 'azure':
      href = `/azure-storage-savings/?location=${location}`;
      break;
    case 'google':
      href = `/google-cloud-storage-savings/?location=${location}`;
      break;      
    default:
      href = '/get-a-quote';
  };
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      component="a"
      href={href}
    >
    Get Quotes
    </Button>
  );
};

const ShowPrices = (props) => {
  const { wr_price, list_price, discount, classes } = props;
  return (
    <>
    <div STYLE="display: flex; flex-direction: column; align-items: flex-end; justify-content: center; padding-right: 1em; padding-top: 1em">
      <div className={classes.discountBadgeContainer}>
        <span className={classes.discountBadgeText}>
          {discount}{' '}off
        </span>
      </div>
      <div className={classes.priceContainer}>
         <del><span className={classes.priceList}>{list_price}</span></del>
         <span className={classes.priceWindRate}>{wr_price}</span> 
      </div>
      <div className={classes.priceSubText}>per TB per Mo</div>
    </div>
    </>
  );
};

const ShowText = (props) => {
  const { desc, storage_class, location, wr_date, classes } = props;
  return (
    <>
    <Typography
    align='left'
    className={classes.priceResultDesc1}            
    >
    {desc}{' '}{`(${storage_class})`}  
    </Typography>
    <Typography
    align='left'
    className={classes.priceResultDesc1}            
    >
    in {location}  
    </Typography>    
    <Typography
    align='left'
    className={classes.priceResultDesc2}            
    >
    {wr_date ? `From a WindRate Partner` : 'Direct from the Provider'}
    </Typography>    
    <Typography
    align='left'
    className={classes.priceResultDesc2}            
    >
    {wr_date ? `(${wr_date})` : ''}
    </Typography>    
    </>
  );
};

const SearchResultMd = props => {
  const { item, classes, key, location } = props;
  return (
    <>
    <Paper className={classes.cardPadding} elevation={0}>
      <Grid container xs={12} className={clsx(classes.gridBottomBorder)}>
        <Grid item xs={2} className={clsx(classes.gridBottomBorder)}>
          <GetProviderImage provider={item.provider} isMd={true} classes={classes} />
        </Grid>    
        <Grid item xs={6} className={clsx(classes.gridBottomBorder, classes.gridRightBorder)}>
          <ShowText 
            desc={item.desc}
            storage_class={item.storage_class}
            location={item.location}
            wr_date={item.wr_date}
            classes={classes}
          />
        </Grid>
        <Grid item xs={4} className={clsx(classes.gridBottomBorder)}>
          <ShowPrices 
          wr_price={item.wr_price} 
          list_price={item.list_price} 
          discount={item.discount} 
          classes={classes}
          />
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

const SearchResultSm = props => {
  const { item, classes, key, location } = props;
  return (
    <>
    <Paper className={classes.cardPadding} elevation={4}>
      <Grid container xs={12} className={clsx(classes.gridBottomBorder)}>
        <Grid item xs={6} style={{ verticalAlign:'middle' }} className={clsx(classes.gridBottomBorder)}>
          <GetProviderImage provider={item.provider} isMd={false} classes={classes} />
        </Grid>
        <Grid item xs={6} className={clsx(classes.gridBottomBorder)}>
          <ShowPrices 
          wr_price={item.wr_price} 
          list_price={item.list_price} 
          discount={item.discount} 
          classes={classes}
          />      
        </Grid>
      </Grid>
      <Grid container xs={12} className={clsx(classes.gridBottomBorder)}>
        <Grid item xs={12} className={clsx(classes.gridBottomBorder)}>
          <ShowText 
            desc={item.desc}
            storage_class={item.storage_class}
            location={item.location}
            wr_date={item.wr_date}
            classes={classes}
          />
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

const SearchResult = props => {
  const { item, isMd, classes, key, location } = props;
    if (isMd) {
      return (
      <SearchResultMd
        item={item}
        key={key}
        classes={classes}
        location={location}
      /> );
    } else {
      return (
      <SearchResultSm
        item={item}
        key={key}
        classes={classes}
        location={location}
      /> );
    };
};

const useStyles = makeStyles(theme => ({
    gridBottomBorder: {
        //borderBottom: `1px solid ${theme.palette.divider}`,
    },
    gridRightBorder: {
      paddingRight: '0.5rem',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    gridButtonContainer: {
      paddingTop:'0', 
      paddingBottom:'0',
      paddingLeft: '0',
    },
    logoImg: {
        //maxWidth: 100,
        padding: theme.spacing(1),
        alignSelf: 'center',
        height: '70%',
        width: '70%',
    },  
    logoImgSm: {
      padding: theme.spacing(1),
      alignSelf: 'center',
      height: '70%',
      width: '70%',      
    },
    resultsSection: {
        maxWidth: 926,
        padding: 0,
        marginTop: '1em',
    },
    priceResultDesc1: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    priceResultDesc2: {
      fontSize: '1rem',
    },    
    priceResultAmt: {
      fontSize: '1.15rem',
      fontWeight: 700,
    },   
    cardPadding: {
      padding: 0,
    },
    discountBadgeContainer: {
      borderStyle: 'solid',
      borderWidth: 0,
      backgroundRepeat: 'no-repeat',
      margin: 0,
      alignItems: 'center',
      borderRadius: '0.25rem',
      display: 'inline-flex',
      gap: '0.25rem',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      padding: '0 0.5rem',
      minHeight: '1.5rem',
      minWidth: '1.5rem',
      backgroundColor: '#D32F2F',
      color: '#FFFFFF',
      float: 'right',
    },
    discountBadgeText: {
      fontFamily: 'system, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif',
      color: '#FFFFFF',
      whiteSpace: 'nowrap',
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      borderStyle: 'solid',
      borderWidth: 0,
      backgroundRepeat: 'no-repeat',
      margin: 0,
      padding: 0,
      fontWeight: 500,
    },
    priceWindRate: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
    },
    priceList: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    priceContainer: {
      float: 'right',
    },
    priceSubText: {
      float: 'right',
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
    mobileQuoteButtonContainer: {
      paddingTop:'0',
    },
    }));

const SearchResults = props => {
    const { provider, location, data } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMd = true;
    /*
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
      defaultMatches: true,
    });*/

    return (
      <Section className={clsx(classes.resultsSection)}>
        {
            data.filter( item => (provider === 'any' || provider === item.provider) && (location === 'any' || location === item.wr_location) ).slice(0,1).map((item, index) => {
            if (provider === 'any' || provider === item.provider) {
                if (location === 'any' || location === item.wr_location) {
                return (
                <>
                <SearchResult
                    item={item}
                    isMd={isMd}
                    key={index}
                    classes={classes}
                    location={location}
                />
                <div style={{ height:'28px'}}></div>
                </>
                )
            }
            }
        })
      }
      </Section>
      );
  };

export default SearchResults;
