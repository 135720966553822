import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { 
    InfoTooltip, 
   } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const AWSInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}  STYLE="font-size: 1.5em">
        <Typography variant="h5">AWS is the leader in cloud storage.  Their S3 storage solution provide very reliable and scalable data storage at competitive prices.  You can use S3 to build secure and scalable data lakes, backup data, archive to the cloud, and move from on premises storage to the cloud.  </Typography>
        <br/>
        <Typography variant="p">With S3, you pay for what you place in your S3 storage buckets based on storage class:</Typography>
        <br/>
        <br/>
        <div STYLE="display: flex; flex-direction: column">
            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">• <InfoTooltip tooltip="Websites, videos that are streamed and data that is used everyday. WindRate classifies this as Hot storage.">S3 Standard</InfoTooltip></div>
                <div>Hot data that is always being accessed.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">• <InfoTooltip tooltip="Data that you add to frequently but only access once a month. WindRate classifies this as Warm storage.">S3 Infrequent Access</InfoTooltip></div>
                <div>Inexpensive data storage with a time commitment.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">• <InfoTooltip tooltip="Data that you need to access once a quarter or less. WindRate classifies this as Cold storage.">Glacier Storage</InfoTooltip></div>
                <div>Even less expensive but requires a longer time commitment.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">• <InfoTooltip tooltip="The lowest cost, but used entirely for backup and archiving purposes. WindRate classifies this as Cold storage.">Deep Glacier Storage</InfoTooltip></div>
                <div>The least expensive but a one-year minimum storage duration.</div>
            </div>
        </div>
        <br/>
        <Typography variant="p">S3 provides object storage that is both secure and reliable.  Move your storage buckets to S3 and get the best pricing on this exceptional platform today through WindRate’s S3 pricing partners.  It is the same AWS S3 at the most competitive prices. Lower your S3 costs!</Typography>
    </div>
  );
};

AWSInfo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AWSInfo;
