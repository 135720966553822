import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  noBg: {
    background: 'transparent',
  },
  copy: {
    textAlign: 'center',
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    maxWidth: 100,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontWeight900: {
    fontWeight: 900,
  },  
}));

const Providers = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const clickProvider = (p) =>
  {
      let provider = p;
      return () =>
      {
        if( props.onClickProvider )
        {
            props.onClickProvider(provider);
        }
    }
  }

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}> 
    {/* <div className={clsx(classes.root, className)} {...rest}> */}
      <div className={classes.copy}>
        <SectionHeader
          title={data.title}
          subtitle={data.subtitle}
          //titleProps={{ className: classes.fontWeight900 }}
          titleVariant="h3"
          subtitleVariant="h5"
          data-aos="fade-up"
          align="center"
        />
        <Grid container justifyContent="space-between">
          {data.providers.map((provider, index) => (
            <Grid item xs={6} sm={2} key={index}>
              <Image
                src={provider.logo}
                alt={provider.name}
                className={classes.logoImg}
                lazy={false}
                onClick={clickProvider(provider.id)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

Providers.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Providers;
