import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { 
    InfoTooltip, 
   } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const AzureInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}  STYLE="font-size: 1.5em">
        <Typography variant="h5">Azure Blob is object cloud storage that is both scalable and secure with end-to-end management.  Why use Azure Blob?  It is a market leading cloud storage product and a perfect solution for storing data, storing information for distributed access and storing data for backup, restore, disaster recovery and archiving.</Typography>
        <br/>
        <div STYLE="display: flex; flex-direction: column">
            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">•&nbsp;<InfoTooltip tooltip="Realtime systems, interactive tools and telemetry. WindRate classifies this as Hot storage.">Azure Blob Premium</InfoTooltip></div>
                <div>Realtime data.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">•&nbsp;<InfoTooltip tooltip="Websites, videos that are streamed and data that is used everyday. WindRate classifies this as Hot storage.">Azure Blob Hot</InfoTooltip></div>
                <div>Hot data that is always being accessed.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">•&nbsp;<InfoTooltip tooltip="Data that you add to frequently but only access once a month. WindRate classifies this as Warm storage.">Azure Blob Cool</InfoTooltip></div>
                <div>Less expensive but requires a longer time commitment.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 11em">•&nbsp;<InfoTooltip tooltip="The lowest cost, but used entirely for backup and archiving purposes. WindRate classifies this as Cold storage.">Azure Blob Archive</InfoTooltip></div>
                <div>The least expensive but a one-year minimum storage duration.</div>
            </div>
        </div>
        <br/>
        <Typography variant="p">Azure Blob allows you to store terabytes and petabytes of data cost effectively and efficiently. Lower your Azure Blob costs with WindRate!</Typography>
    </div>
  );
};

AzureInfo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AzureInfo;
