import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  videoIframe: {
    boxShadow: `0 5px 15px 0 ${theme.palette.cardShadow}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
  },
}));

const VideoPlayer = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const [play,setPlay] = React.useState(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
/*
  const clickVideo = (e) =>
  {
    setPlay(true);
  }

  return (
              { 
        play
        
        ?
            <iframe src="https://player.vimeo.com/video/701912065?h=351bcf3279&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="583" height="328" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="WindRate_Final_Render_LowerSound.mp4"></iframe>
        
        :
            <div ID="VIDEOCLICKER" STYLE="width: 100%; height: 315px;  background-image: url('/images/VideoPlay.png')" onClick={clickVideo}></div>
        } 
  )
*/

  return (
    ( isLg ?
        <div STYLE="padding: 0.5em; background: black; display: grid; justify-content: center; align-items: center; width: 550px">
        <iframe src="https://player.vimeo.com/video/701912065?h=351bcf3279&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="540" height="303" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="WindRate_Final_Render_LowerSound.mp4"></iframe>
    </div>       
        :
    ( isMd ?
        <div STYLE="padding: 0.5em; background: black; display: grid; justify-content: center; align-items: center; width: 420px">
            <iframe src="https://player.vimeo.com/video/701912065?h=351bcf3279&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="410" height="242" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="WindRate_Final_Render_LowerSound.mp4"></iframe>
        </div>
        :
        <div STYLE="display: grid; justify-content: center; align-items: center; width: 100%">
            <div STYLE="padding: 0.5em; background: black; display: grid; justify-content: center; align-items: center; width: 360px">
                <iframe src="https://player.vimeo.com/video/701912065?h=351bcf3279&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="350" height="196" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="WindRate_Final_Render_LowerSound.mp4"></iframe>
            </div>
        </div>
    ))
  )
};

        


VideoPlayer.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default VideoPlayer;
