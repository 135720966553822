import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Section, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  hero: {
    background: theme.palette.alternate.dark,
  },
  heroSml: {
    background: theme.palette.alternate.dark,
    padding: theme.spacing(4, 0),
  },
  heroWrapper: {
    position: 'relative',
  },
  heroImageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  heroGrid: {
    maxWidth: 926,
  },
  searchGrid: {
    zIndex: 3,
  },
  searchGridText: {
    maxWidth: 605,
  },
  textField: {
    width: '100%',
  },
  searchButton: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontWeight: 900,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

const SearchHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [location, setLocation] = React.useState('');
  const [provider, setProvider] = React.useState('');

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  function handleSubmit() {
    window.location.href = `/cloud-storage-search-results?provider=${provider ? provider : 'any'}&location=${location ? location : 'any'}`;
  };

  return (
    <>
    <section className={clsx(isMd ? classes.hero : classes.heroSml)}>
      <Section className={classes.heroWrapper}>
        <Grid container spacing={isMd ? 6 : 4} className={classes.heroGrid}>
          <Grid item xs={12} className={classes.searchGridText}>
            <Typography
              variant="h2"
              color="inherit"
              className={classes.title}
            >{
            <span>
            Find the<br /> 
            <Typography component="span" variant="inherit" color="primary">Best Deals</Typography><br />
            on Cloud Storage
            </span>
            }
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.searchGrid}>
            <CardBase variant="outlined" withShadow liftUp>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-provider-label"><i className={clsx('fas fa-cloud',)} />&nbsp;&nbsp;Preferred Provider</InputLabel>
                  <Select
                    value={provider}
                    onChange={handleProviderChange}
                    labelWidth={160}
                    inputProps={{
                      name: 'class',
                      id: 'outlined-provider-label',
                    }}
                    className={classes.textField}
                    size={isMd ? 'medium' : 'small'}
                  >
                    <MenuItem value={"any"}>Any Provider</MenuItem>
                    <MenuItem value={"aws"}>Amazon Web Services (AWS)</MenuItem>
                    <MenuItem value={"azure"}>Microsoft Azure</MenuItem>
                    <MenuItem value={"google"}>Google Cloud Platform (GCP)</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="outlined-location-label"><i className={clsx('fas fa-map-marker-alt',)} />&nbsp;&nbsp;Preferred Location</InputLabel>
                  <Select
                    value={location}
                    onChange={handleLocationChange}
                    labelWidth={160}
                    inputProps={{
                      name: 'location',
                      id: 'outlined-location-label',
                    }}
                    className={classes.textField}
                    size={isMd ? 'medium' : 'small'}
                  >
                    <MenuItem value={"any"}>Any Location</MenuItem>
                    <MenuItem value={"us_east"}>US (East)</MenuItem>
                    <MenuItem value={"us_central"}>US (Central)</MenuItem>
                    <MenuItem value={"us_west"}>US (West)</MenuItem>
                    <MenuItem value={"gov_us"}>US (Gov)</MenuItem>
                    <MenuItem value={"canada"}>Canada</MenuItem>
                    <MenuItem value={"europe_west"}>Europe (West)</MenuItem>
                    <MenuItem value={"europe_east"}>Europe (East)</MenuItem>
                    <MenuItem value={"asia_pacific"}>Asia Pacific</MenuItem>
                    <MenuItem value={"middle_east"}>Middle East</MenuItem>
                    <MenuItem value={"south_america"}>South America</MenuItem>
                    <MenuItem value={"africa"}>Africa</MenuItem>
                    <MenuItem value={"china"}>China</MenuItem>
                  </Select>
                </FormControl>                
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    className={classes.searchButton}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >{
                    <span><i className={clsx('fas fa-search',)} />&nbsp;&nbsp;Search Prices</span>
                  }
                  </Button>
                </Grid>
              </Grid>
            </CardBase>
          </Grid>
        </Grid>
        <div className={classes.heroImageContainer}></div>
      </Section>
    </section>
    </>
  );
};

export default SearchHero;
