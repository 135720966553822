import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { 
    InfoTooltip, 
   } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const GoogleInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}  STYLE="font-size: 1.5em">
        <Typography variant="h5">Google Cloud Storage is your answer for world class object storage designed for businesses and organizations.</Typography>
        <br/>
        <Typography variant="p" >Google Cloud storage offers features like <InfoTooltip tooltip="Automatic movement of data to lower cost storage.">Object Lifecycle Management</InfoTooltip> (OLM), <InfoTooltip tooltip="Google has datacenters all over the world, choose the best one or more than one to store your valuable information.">Multiple Redundancy Options</InfoTooltip>, <InfoTooltip tooltip="A fast way to plan, coordinate and move your data to Google.">Storage Transfer Service</InfoTooltip>, and easy to use cost structuring through Google Storage tiers:
        </Typography>
        <br/>
        <br/>
        <div STYLE="display: flex; flex-direction: column">
            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 10em">• <InfoTooltip tooltip="Websites, videos that are streamed and data that is used everyday. WindRate classifies this as Hot storage.">Standard Storage</InfoTooltip></div>
                <div>Hot data that is always being accessed.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 10em">• <InfoTooltip tooltip="Data that you add to frequently but only access once a month. WindRate classifies this as Warm storage.">Nearline Storage</InfoTooltip></div>
                <div>Inexpensive data storage with a time commitment.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 10em">• <InfoTooltip tooltip="Data that you need to access once a quarter or less. WindRate classifies this as Warm storage.">Coldline Storage</InfoTooltip></div>
                <div>Even less expensive but requires a longer time commitment.</div>
            </div>

            <div STYLE="display: flex; flex-direction: row; width: 100%">
                <div STYLE="min-width: 10em">• <InfoTooltip tooltip="The lowest cost, but used entirely for backup and archiving purposes. WindRate classifies this as Cold storage.">Archive Storage</InfoTooltip></div>
                <div>The least expensive but a one-year minimum storage duration.</div>
            </div>
        </div>
        <br/>
        <Typography variant="p">Google Cloud Storage provides object storage that is both secure and reliable. Move your storage buckets to GCP and get the best pricing on this exceptional platform today through WindRate’s GCP pricing partners. It is the same Google Cloud Storage at the most competitive prices!</Typography>
    </div>
  );
};

GoogleInfo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default GoogleInfo;
