import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid,Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { Image } from 'components/atoms';
import { 
    InfoTooltip, 
   } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const MSPInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}  STYLE="font-size: 1.5em">
        <div STYLE="padding-bottom: 16px">
            <Grid container justifyContent="space-around">
            {data.providers.map((provider, index) => (
                <Grid item xs={6} sm={2} key={index}>
                    <Image
                        src={provider.logo}
                        alt={provider.name}
                        className={classes.logoImg}
                        lazy={false}
                    />
                </Grid>
            ))}
            </Grid>
        </div>

         <Typography variant="h5">You specialize in technology, not marketing. Let us help you focus on the important work.</Typography>
         <br/>
         <Typography variant="h5">Finding a wortwhile marketing partner can be a challenge.</Typography>
         <br/>
         <Typography variant="h5" STYLE="color:#ae36f1">Let us take that pain away.</Typography>
         <br/>
         <Typography variant="h5">We pre-qualify leads, get project details, and you simply bid on those leads.</Typography>
         <br/>
         <Typography variant="h5">You don't need to waste your valuable time wrestling with arcade advertising tools and referral tools.</Typography>
         <br/>
         <Typography variant="h5" STYLE="color:#ae36f1">Let us do the work for you and start with the lead instead of ending there.</Typography>
         <br/>
    </div>
  );
};

MSPInfo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default MSPInfo;
