import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Typography,
  Button,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingBottom: 0,
    background: theme.palette.alternate.dark,
  },
  heroSml: {
    background: theme.palette.alternate.dark,
    marginTop: theme.spacing(4),
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },

}));

const SearchResultsHero = props => {
  const { provider, location } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const searchButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href="/cloud-storage-search"
    >
      Search again
    </Button>
  );

  let displayProvider = 'Any';
  switch(provider) {
    case 'aws':
      displayProvider = 'AWS S3';
      break;
    case 'azure':
      displayProvider = 'Azure Blob';
      break;
    case 'google':
      displayProvider = 'Google Cloud';
      break;
    default:
      displayProvider = 'Any';
  };

  let displayLocation = 'Any';
  let displayLocationBefore = '';
  switch(location) {
    case 'us_east':
      displayLocationBefore = 'the ';
      displayLocation = 'US (East)';
      break;
    case 'us_central':
      displayLocationBefore = 'the ';
      displayLocation = 'US (Central)';
      break;
    case 'us_west':
      displayLocationBefore = 'the ';
      displayLocation = 'US (West)';
      break;
    case 'gov_us':
      displayLocationBefore = 'the ';
      displayLocation = 'US (Gov)';
      break;
    case 'canada':
      displayLocationBefore = 'the ';
      displayLocation = 'Canada';
      break;
    case 'europe_west':
      displayLocationBefore = 'the ';
      displayLocation = 'Europe (West)';
      break;
    case 'europe_east':
      displayLocationBefore = 'the ';
      displayLocation = 'Europe (East)';
      break;
    case 'asia_pacific':
      displayLocationBefore = 'the ';
      displayLocation = 'Asia Pacific';
      break;
    case 'middle_east':
      displayLocationBefore = 'the ';
      displayLocation = 'Middle East';
      break;        
    case 'south_america':
      displayLocationBefore = 'the ';
      displayLocation = 'South America';
      break;
    case 'africa':
      displayLocationBefore = 'the ';
      displayLocation = 'Africa';
      break;
    case 'china':
      displayLocationBefore = 'the ';
      displayLocation = 'China';
      break;                  
    default:
      displayLocationBefore = '';
      displayLocation = 'Any';
  };

  return (
    <>
    <Section className={clsx(isMd ? classes.hero : classes.heroSml)}>
        <SectionHeader
          title={
            <span>
              Recent results for
              <br />
              <Typography component="span" variant="inherit" color="primary">{displayProvider}</Typography> Storage 
              <br />
              in {displayLocationBefore}<Typography component="span" variant="inherit" color="primary">{displayLocation}</Typography> Region
            </span>
          }
          //subtitle={
          //  <span>
          //    {data.subtitle}
          //  </span>
          //}
          align="center"
          disableGutter
          titleVariant="h3"
          subtitleVariant="h5"  
          titleProps={{ className: classes.fontWeight900 }}
          ctaGroup={[searchButton]}
        />
      </Section>
    </>
  );
};

export default SearchResultsHero;
