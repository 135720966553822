import React, { useEffect } from 'react';
import axios from 'axios';
// import { Icon } from 'components/atoms';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, TextField, Select, InputLabel, MenuItem, FormControl, Link } from '@material-ui/core';
import validator from 'validator';
import { PhoneRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },  
  fontWeight900: {
    fontWeight: 900,
  },
  purple: {
    color: '#ae36f1'
  },    
}));

const MSPSignupForm = props => {

  // Helpers for UTM scraping
  const getCookie = (key) => {
    let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  
  // Scrape UTM parameters, and store them in cookies.
  // It (intentionally) locks in the first use of the parameters.
  // We could conceivably move this block into a separate component if we ever want to scrape from landing pages that don't have a storage form.
  // BB 12-10-2021
  let utm_source,utm_medium,utm_content,utm_campaign,utm_term,gclid;
  
  useEffect(() =>
  {
    if( (utm_source=getCookie("utm_source")) === "" ) {
        utm_source = getParameterByName("utm_source");
        document.cookie = "utm_source="+utm_source
      }
      
      if( (utm_medium=getCookie("utm_medium")) === "" ) {
        utm_medium = getParameterByName("utm_medium");
        document.cookie = "utm_medium="+utm_medium
      }
      
      if( (utm_content=getCookie("utm_content")) === "" ) {
        utm_content = getParameterByName("utm_content");
        document.cookie = "utm_content="+utm_content
      }

      if( (utm_campaign=getCookie("utm_campaign")) === "" ) {
        utm_campaign = getParameterByName("utm_campaign");
        document.cookie = "utm_campaign="+utm_campaign
      }

      if( (utm_term=getCookie("utm_term")) === "" ) {
        utm_term = getParameterByName("utm_term");
        document.cookie = "utm_term="+utm_term
      }
      
      if( (gclid=getCookie("gclid")) === "" ) {
        gclid = getParameterByName("gclid");
        document.cookie = "gclid="+gclid
      }
  });
  
  // used to allow random initialization of default region "1-3" for ex.
  const randomRange = (value) => {
    
    if( (String(value).includes("-")))
    {
        let min = +(String(value)).split('-')[0];
        let max = +(String(value)).split('-')[1];
        return Math.round((Math.random() * (max - min) + min));
    }
    else
    {
        return +value;
    }
  }

  const { data, show_extended_fields, quote_provider, refPage, location } = props;

  const classes = useStyles();

  const [preferredProvider, setPreferredProvider] = React.useState(data.preferredPlatformSelectDefaultValue);
  const [storage, setStorage] = React.useState( data.storageSelectDefaultValue );
  const [region, setRegion] = React.useState(randomRange(data.regionSelectDefaultValue));
  const [email, setEmail] = React.useState('');
  const [businessName, setBusinessName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [platform, setPlatform] = React.useState('');
  const [territory, setTerritory] = React.useState('');
  
  useEffect(() => {

    let eventData = 
    {
        selected: region,
        wr_region: 'us_east',
        region: data.regionSelectValues[displayed_provider().provider_id].filter( r => r.value === region )[0]
    }

    if( eventData.region.generic_region_id === 1 ) eventData.wr_region = 'us_west';
    if( eventData.region.generic_region_id === 2 ) eventData.wr_region = 'us_east';
    if( eventData.region.generic_region_id === 3 ) eventData.wr_region = 'us_central';

     if( props.onRegionChanged )
     {
         props.onRegionChanged(eventData);
     }

  }, [region]);

  const displayed_provider = () =>
  {
    return data.preferredPlatformSelectValues.filter( p => p.value === preferredProvider )[0];
  }

  const [regionList, setRegionList] = React.useState(data.regionSelectValues[displayed_provider().provider_id])
  const updateRegionList = ( provider_id ) =>
  {
      console.log("updating region list");
      let selectedGenericRegion = data.regionSelectValues[provider_id].filter( r => r.value === region )[0].generic_region_id;
      setRegionList(data.regionSelectValues[provider_id]);
      setRegion( data.regionSelectValues[provider_id].filter( r => r.generic_region_id === selectedGenericRegion )[0].value );
  }

  const [form_submitting, setFormSubmitting] = React.useState(false);

  
  const [email_warning, setEmailWarning] = React.useState(null)

  const handleChange = (event) => {
    switch(event.target.name) {
      case "storage":
        setStorage(event.target.value);
        break;
      case "region":
        setRegion(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;  
      case "businessName":
        setBusinessName(event.target.value);
        break;     
      case "territory":
        setTerritory(event.target.value);
        break;     
      case "phoneNumber":
        setPhoneNumber(event.target.value);
        break;     
      case "platform":
        setPlatform(event.target.value);
        break;     
      case "preferredProvider":
        setPreferredProvider(event.target.value);
        updateRegionList(data.preferredPlatformSelectValues.filter( p => p.value === event.target.value )[0].provider_id);
        break;        
      default:
        // code block
    }
  };

  const submitRegistration = (event) => {

    event.preventDefault();
    setFormSubmitting(true);

    let body = 
    {
        email : email,
        metadata:
        {
        "source" : "msp-signup-form",
        "customer_business_name" : businessName,
        "customer_phone_number" : phoneNumber,
        "customer_platform" : platform,
        "utm_campaign" : utm_campaign,
        "utm_term" : utm_term,
        "utm_source" : utm_source,
        "utm_content" : utm_content,
        "utm_medium" : utm_medium,
        "gclid" : gclid,
        "refPage" : refPage,
        }
    };

    //
    //let postEndpoint = 'https://account.windrate.com/registration/register/quoteform';

    // dev endpoint for testing
    //let postEndpoint = 'http://dev2.windrate.com/registration/register/salesform';
    let postEndpoint = 'https://account.windrate.com/registration/register/salesform';
    
    if( window.gtag != undefined )
    {
        window.gtag('set', 'user_data', {
            "email": email
        });
    }

    axios.post( postEndpoint, body, 
    { 
        crossDomain: true
    } ).then(
        (response) => {

            const redirectTimeout = window.setTimeout(() => { callback() }, 2000);

            var callback = function () {
                try
                {
                    clearTimeout(redirectTimeout);
                }
                catch
                {

                }
                finally
                {
                    window.location = "/onboardmsp?redirect=" + encodeURIComponent(response.data);
                }
            };
                        
            if( window.gtag != undefined )
            {

                window.gtag('event', 'conversion', {
                    'send_to': 'AW-448182142/zVPnCJmM2KsDEP7u2tUB',
                    'event_callback': callback
                });
            }
            else
            {
                callback();
            }
        })
        .catch(error => {
          setFormSubmitting(false);

          if( error?.response?.data?.message != null && error?.response?.data?.message.includes("Duplicate entry"))
          {
            setEmailWarning( "That e-mail is already taken, try resetting your password from the login page if you already have an account." );
          }
          else
          {
            setEmailWarning( "Sorry there was an issue submitting your request. Try again in a few minutes." );
          }
        }
    );
  }

  // update the starting provider to match the lander query string or landing page configuration
  useEffect( ()=>
  {
      let lander = getParameterByName("lander"); // legacy query string entry points

      if( quote_provider !== null && quote_provider !== undefined ) // use a hard coded quote_provider preferentially
      {
        setPreferredProvider(data.preferredPlatformSelectValues.filter( p => p.provider_id === quote_provider )[0].value);
        updateRegionList(quote_provider);
      }
      else
      if( lander !== null && lander !== undefined && data.preferredPlatformSelectValues.some( p => p.provider_id === lander) ) // otherwise use the lander query string if one was provided
      {
        setPreferredProvider(data.preferredPlatformSelectValues.filter( p => p.provider_id === lander )[0].value);
        updateRegionList(lander);
      }
      // otherwise leave the default 'any'
  }, []);

  return (
    <div className={classes.root}>
      <form
        name={data.name}
        method="post"
        action={data.action}
        autoComplete="off"
      >
        <input type="hidden" name="form-name" value={data.name} />
        <Grid container spacing={2}>
        
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
            {/* data.title */}
            {
            <span className={classes.fontWeight900}>{ props.quote_skin && props.quote_skin.quoteTitleLeft  ? props.quote_skin.quoteTitleLeft : ''}<span className={classes.purple}>{props.quote_skin && props.quote_skin.quoteTitleRight ? props.quote_skin.quoteTitleRight : 'Start Saving Now'}</span></span>
            }
            </Typography>

            <Typography variant="h6" color="textSecondary" align="center">
            {data.subtitle}
            </Typography>

          </Grid>

          {
              show_extended_fields ?
              <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    id="businessName" 
                    label={data.businessNameLabel}
                    placeholder={data.businessNamePlaceholder}
                    value={businessName}
                    autoComplete="businessName"
                    size="medium"
                    name="businessName"  
                    onChange={handleChange}
                    type="businessName"                      
                /> 
              </Grid>
              : ""
          }

          <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    id="phoneNumber" 
                    label={data.MSPphoneNumberLabel}
                    placeholder={data.MSPphoneNumberPlaceholder}
                    value={phoneNumber}
                    autoComplete="phoneNumber"
                    size="medium"
                    name="phoneNumber"  
                    onChange={handleChange}
                    type="phoneNumber"                      
                /> 
          </Grid>

          <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    id="platform" 
                    label={data.MSPplatformLabel}
                    placeholder={data.MSPplatformPlaceholder}
                    value={platform}
                    autoComplete="platform"
                    size="medium"
                    name="platform"  
                    onChange={handleChange}
                    type="platform"                      
                /> 
          </Grid>

          <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    id="territory" 
                    label={data.MSPterritoryLabel}
                    placeholder={data.MSPterritoryPlaceholder}
                    value={territory}
                    autoComplete="territory"
                    size="medium"
                    name="territory"  
                    onChange={handleChange}
                    type="territory"                      
                /> 
          </Grid>

          <Grid item xs={12}>
          <TextField 
            fullWidth 
            id="email" 
            label={data.emailLabel}
            placeholder={data.emailPlaceholder}
            value={email}
            autoComplete="email"
            size="medium"
            name="email"  
            onChange={handleChange}
            type="email"                      
          /> 
          </Grid>

          <Grid item xs={12}>
            
            <Typography variant="subtitle2" gutterBottom>
            
            </Typography>
            {/*
            Fields that are marked with * sign are required.
            */}
            
            { form_submitting ?
            <div style={{paddingTop:"1em"}}><i  style={{marginRight:"0.5em"}} class="fa fa-btn fa-spinner fa-spin"></i>Getting ready...</div>
            :
                <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                disabled={validator.isEmail(email) ? false : true}
                onClick={submitRegistration}
                >
                    { props.quote_skin && props.quote_skin.button_text ? props.quote_skin.button_text : 'Send' }
                </Button>
            }
            {/*
            <Link to="/login">
            <Typography variant="subtitle2" gutterBottom align="right">Need another platform?</Typography>
            </Link>
            */}            
          </Grid>
        </Grid>

        {email_warning?
            <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom style={{marginTop:"4em"}}>
             {email_warning}  
            </Typography>
            </Grid> : ""
        }
        <input name="hiddenInput" value="hiddenValue" type="hidden" />
      </form>
    </div>
  );
};

export default MSPSignupForm;
