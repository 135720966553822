import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Section, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import   
{
    AWSInfo,
    AzureInfo,
    GoogleInfo,
    SMBInfo,
    EnterpriseInfo,
    LawFirmInfo
} from 'components/windrate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  noBg: {
    background: 'transparent',
  },
  copy: {
    textAlign: 'center',
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    maxWidth: 100,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontWeight900: {
    fontWeight: 900,
  },    
  noBottomPadding: {
    paddingBottom: 0,
  },

}));

const ProvidersInfoAny = props => {
  const { data, provider, className, ...rest } = props;

  let [selectedProvider, setSelectedProvider] = useState(provider ?? 'aws');
  
  const classes = useStyles();

  const clickProvider = (p) =>
  {
      let provider = p;
      return (() => setSelectedProvider(provider));
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}> 
      <div className={classes.copy}>
        <SectionHeader
          title={data.title}
          subtitle={data.subtitle}
          //titleProps={{ className: classes.fontWeight900 }}
          titleVariant="h3"
          subtitleVariant="h5"
          align="center"
        />
        <Grid container justifyContent="space-around">
          {data.providers.map((provider, index) => (
            <Grid item xs={6} sm={2} key={index}>
                {selectedProvider === provider.id ?
                    <Image
                        src={provider.logo}
                        alt={provider.name}
                        className={classes.logoImg}
                        lazy={false}
                        onClick={clickProvider(provider.id)}
                    />
                    :
                    <Image
                        src={provider.logo}
                        alt={provider.name}
                        className={classes.logoImg}
                        lazy={false}
                        onClick={clickProvider(provider.id)}
                        STYLE="filter: grayscale(100%)"
                    />
                }
            </Grid>
          ))}
        </Grid>
      </div>

        <div STYLE="padding-top:2em">
            {selectedProvider === "smb" &&
                <SMBInfo/>
            }

            {selectedProvider === "law-firm" &&
                <LawFirmInfo/>
            }

            {selectedProvider === "enterprise" &&
                <EnterpriseInfo/>
            }

            {selectedProvider === "google" &&
                <GoogleInfo/>
            }

            {selectedProvider === "aws" &&
                <AWSInfo/>
            }

            {selectedProvider === "azure" &&
                <AzureInfo/>
            }
        </div>
    </div>


  );
};

ProvidersInfoAny.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default ProvidersInfoAny;
