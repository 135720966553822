import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Button, InputAdornment } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Icon } from 'components/atoms';

const Subscription = props => {
  const { data, className, ...rest } = props;

  return (
    <div className={className} {...rest}>
      <form
          name={data.name}
          method="post"
          action={data.action}
          autoComplete="off"
          target="_blank"
          novalidate
      >
      <input type="hidden" name="form-name" value={data.name} />      
      <CardBase withShadow data-aos="fade-up">
        <SectionHeader
          title={data.title}
          subtitle={data.subtitle}
          fadeUp
        />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              fullWidth
              label={data.label}
              variant="outlined"
              type="email"
              //value="" 
              name="EMAIL"
              id="mce-EMAIL"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      fontIconClass="fas fa-paper-plane"
                      fontIconColor="primary" //{colors.indigo[900]}
                    />
                  </InputAdornment>
                ),
                }}              
            />
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_189e7b1598bc57ab230e76cc9_3d8d26343e" tabindex="-1" value="" /></div>
          </Grid>
          <Grid item xs={12} sm={3}>
          <Button fullWidth variant="contained" color="primary" size="large" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">
            {data.buttonText}
          </Button>
          </Grid>
        </Grid>
      </CardBase>
      </form>
    </div>
  );
};

Subscription.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Subscription;
