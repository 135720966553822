import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const InfoTooltip = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Tooltip title={props.tooltip} classes={{tooltip: classes.tooltip}} arrow><div STYLE="display:inline-block; text-decoration: underline; text-decoration-style: dotted;">{props.children}</div></Tooltip>
  );
};

InfoTooltip.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default InfoTooltip;
