import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// import { CardBase } from 'components/organisms';
import Fab from '@material-ui/core/Fab';

const OnboardBody = props => {
  const { data, className, ...rest } = props;

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }  

  const performRedirect = () =>
  {
      let target = window.location = getParameterByName("redirect");
      if( target !== null && target !== "" )
      {
        window.location = target;
      }
  };

  useEffect(() =>
  {
      const timer = setTimeout(() => {
        // After a couple seconds perform the redirect
        performRedirect();
      }, 2000)
  
      return () => {
        clearTimeout(timer)
      }
  });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
            <p>{data.bodytext}</p>
        </Grid>
        <Grid item xs={12} md={12}>
            <Fab variant="extended" color="primary" aria-label="add" onClick={() => { performRedirect() }}>
                {data.buttontext}
            </Fab>
        </Grid>
      </Grid>
    </div>
  );
};

OnboardBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default OnboardBody;
