import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const Hero = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <Section className={classes.noPaddingBottom}>
        <SectionHeader
          title={
            <span>
              {data.title}
              <br />
              <Typography component="span" variant="inherit" color="primary" dangerouslySetInnerHTML={{__html:data.emphasized}}></Typography>
            </span>
          }
          subtitle={
            <span>
              {data.subtitle}
            </span>
          }
          align="center"
          disableGutter
          //titleVariant="h2"
          //subtitleVariant="h4"
          titleVariant={data.titleVariant}
          subtitleVariant={data.subtitleVariant}  
          titleProps={{ className: classes.fontWeight900 }}
        />
      </Section>
      </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
