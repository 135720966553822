import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { StorageQuoteForm, VideoPlayer, SearchResults } from 'components/windrate';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import storagequote from "content/global/storagequote.yaml";
import SingleSearchResult from '../SingleSearchResult';
const chartData = require('content/global/searchdata.json');

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: 0,
  },  
  fontWeight900: {
    fontWeight: 900,
  },
  whiteText: {
    color: theme.palette.common.white,
  },    
}));

const TextCard = props => {
  const { data, isMd } = props;
  const classes = useStyles();  
  return (
    <Grid item xs={12} md={6}>
    <SectionHeader
      title={data.title}
      subtitle={data.subtitle}
      align="left"
      disableGutter
      titleVariant={data.titleVariant}
      subtitleVariant={data.subtitleVariant} 
      titleClasses={!isMd ? classes.whiteText : ''}
      subtitleClasses={!isMd ? classes.whiteText : ''}
    />
    <VideoPlayer data={data.videoSection}/>
  </Grid>
  );
};

const QuoteCard = props => {
  const { data, storagequote, extra_card_style, show_extended_fields, location, enable_pricing } = props;
  
  const [selectedRegion,setSelectedRegion] = useState('any')

  const onRegionChanged = (e) =>
  {
      if( props.onRegionChanged )
      {
          props.onRegionChanged(e);
      }

      setSelectedRegion(e.wr_region);
  }

  return (
    <Grid item xs={12} md={6}>
    <CardBase withShadow STYLE={extra_card_style}>
      <StorageQuoteForm data={storagequote} show_extended_fields={show_extended_fields} quote_skin={data.quote_skin} quote_provider={data.quote_provider} refPage={data.refPage} location={location} onRegionChanged={onRegionChanged} />

      { enable_pricing &&
      (
          <div>
            <div STYLE="padding-top:1.5em; padding-bottom:1em">get free, personalized quotes like this real example</div>
            <SingleSearchResult 
                    location={selectedRegion}
                    provider={data.quote_provider || 'any'}
                    data={chartData}
                />
        </div>
        )}

    </CardBase>
  </Grid>
    );
  };

const QuoteForm2 = props => {
  const { data, show_extended_fields, quote_provider, className, location, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        { isMd ? 
        <>
        <TextCard data={data} isMd={isMd}/>
        <QuoteCard data={data} storagequote={storagequote} show_extended_fields={show_extended_fields} location={location} extra_card_style="height:380px" /> 
        </>
        : 
        <>
        <QuoteCard data={data} storagequote={storagequote} show_extended_fields={show_extended_fields} location={location}/> 
        <TextCard data={data} />
        </>
        }
      </Grid>
    </div>
  );
};

QuoteForm2.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default QuoteForm2;
