import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { 
  Typography,
//  Grid, 
//  Avatar, 
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

/*
const useStyles = makeStyles(() => ({
  avatar: {
    maxWidth: 80,
    height: '100%',
    width: '100%',
  },
}));
*/

const Partners = props => {
  const { data, className, ...rest } = props;
  // const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>
            {data.title}<Typography color="secondary" variant="inherit" component="span">{data.emphasized}</Typography>
          </span>
        }
        subtitle={data.subtitle}
        fadeUp
      />
      {/*
      <Grid container data-aos="fade-up">
        {data.partners.map((item, index) => (
          <Grid key={index} item container xs={4} sm={2} justify="center">
            <Avatar
              src={item.logo}
              alt={item.name}
              className={classes.avatar}
            />
          </Grid>
        ))}
      </Grid>
      */}
    </div>
  );
};

Partners.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Partners;
