// import { mergeClasses } from '@material-ui/styles';
import React from 'react';

import * as classes from './YouTubeVideoModal.module.css';

class YouTubeVideoModal extends React.Component {
  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded

    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.initialzePlayer;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  };

  initialzePlayer = () => {
    // the Player object is created uniquely based on the id in props
    window.__frontpage_video_player = new window.YT.Player('_frontpage_video_player', {
        width: "100%",
        height: "100%",
        events: {
            'onReady': this.onPlayerReady,
            'onStateChange': this.onPlayerStateChange
        }
    });

    window.__frontpage_play_video = () => this._frontpage_play_video();
    window.__what = {};
  };

  _frontpage_play_video = () => 
  {
      if( window.__frontpage_video_player === undefined ) return;
      if( window.document.getElementById('_frontpage_video_overlay')) {
          window.document.getElementById('_frontpage_video_overlay').style.display = "grid";
          window.document.getElementById('_frontpage_video_overlay').style.visibility = "visible";
      }
      if( !window._frontpage_video_loaded )
      {
            window.__frontpage_video_player.loadVideoById("j-tCtk4e6Ys");
            window._frontpage_video_loaded = true;
      }
      else
      {
            window.__frontpage_video_player.playVideo();
      }
  }

  stopVideo = () => {
    if( window.__frontpage_video_player === undefined ) return;
    if( window.document.getElementById('_frontpage_video_overlay'))
    {
        window.document.getElementById('_frontpage_video_overlay').style.display = "none";
        window.document.getElementById('_frontpage_video_overlay').style.visibility = "hidden";
    }
    window.__frontpage_video_player.stopVideo();
  };

  onPlayerReady = event => {

  };

  onPlayerStateChange = event => {
    if( event.data === 0 ) this.stopVideo();
  };

  render = () => {
//    const { id } = this.props;
    return (
        <div role="presentation" id="_frontpage_video_overlay" className={classes.video_overlay} onClick={this.stopVideo} onKeyDown={this.handleClick}>
            <div class={classes.video_overlay_content}>
                <div id="_frontpage_video_player"></div>
            </div>
        </div>
    );
  };
}

export default YouTubeVideoModal;