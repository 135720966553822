import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  videoIframe: {
    boxShadow: `0 5px 15px 0 ${theme.palette.cardShadow}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
  },
}));

const VideoSectionReversed = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <iframe
            className={classes.videoIframe}
            title={data.videoFrame.title}
            width="100%"
            height="315"
            allowfullscreen
            src={data.videoFrame.src}
            frameBorder="0"
            allow={data.videoFrame.allow}
          />
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={5} data-aos={'fade-up'}>
          <SectionHeader
            title={data.title}
            subtitle={data.subtitle}
            ctaGroup={[
              <Button
                href="/contact"
                variant="contained"
                color="primary"
                size={isMd ? 'large' : 'medium'}
              >
                {data.callToAction.buttonText}
              </Button>,
            ]}
            align={isMd ? 'left' : 'center'}
            disableGutter
            titleVariant="h3"
          />
        </Grid>        
      </Grid>
    </div>
  );
};

VideoSectionReversed.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default VideoSectionReversed;
