import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography, List, ListItem } from '@material-ui/core';
import { 
    InfoTooltip, 
   } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
tooltip: {
    fontSize: "1.5em",
    },
  noPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 100,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontWeight900: {
    fontWeight: 900,
  },
}));

const EnterpriseInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}  STYLE="font-size: 1.5em">
         <Typography variant="h5">WindRate knows that shopping for tech solutions can be intimidating for enterprise users.</Typography>
         <br/>
         <Typography variant="h5">Many managed service providers mark up storage by 200%, 300% even 400%!</Typography>
         <br/>
         <Typography variant="h5">Finding a fair partner can be a challenge.</Typography>
         <br/>
         <Typography variant="h5" STYLE="color:#ae36f1">Let us take that pain away.</Typography>
         <br/>
         <Typography variant="h5">When you’re shopping for any product, what is the most important detail?  The price!</Typography>
         <br/>
         <Typography variant="h5">You don't need to waste your valuable time sitting through calls, emails, to get the best cloud storage prices for your enterprise.</Typography>
         <br/>
         <Typography variant="h5" STYLE="color:#ae36f1">Let us do the work for you and start with the price instead of ending there.</Typography>
         <br/>
    </div>
  );
};

EnterpriseInfo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default EnterpriseInfo;
