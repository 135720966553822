import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { StorageQuoteForm } from 'components/windrate';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import storagequote from "content/global/storagequote.yaml";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },  
  fontWeight900: {
    fontWeight: 900,
  },
  whiteText: {
    color: theme.palette.common.white,
  },    
}));

const TextCard = props => {
  const { data, isMd } = props;
  const classes = useStyles();  
  return (
    <Grid item xs={12} md={6}>
    <SectionHeader
      title={data.title}
      subtitle={data.subtitle}
      align="left"
      disableGutter
      titleVariant={data.titleVariant}
      subtitleVariant={data.subtitleVariant} 
      titleClasses={!isMd ? classes.whiteText : ''}
      subtitleClasses={!isMd ? classes.whiteText : ''}
    />
    <Fab variant="extended" color="primary" aria-label="add" className={classes.margin} onClick={() => { window?.__frontpage_play_video() }}>
      <OndemandVideoIcon />
      <span>&nbsp;&nbsp;&nbsp;</span>See how it works
    </Fab>
  </Grid>
  );
};

const QuoteCard = props => {
  const { data, storagequote, show_extended_fields, location } = props;
  return (
    <Grid item xs={12} md={6}>
    <CardBase withShadow liftUp>
      <StorageQuoteForm data={storagequote} show_extended_fields={show_extended_fields} quote_provider={data.quote_provider} refPage={data.refPage} location={location} />
    </CardBase>
  </Grid>
    );
  };

const QuoteFormOnly = props => {
  const { data, show_extended_fields, quote_provider, className, location, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container spacing={1} justifyContent="center">
        { isMd ? 
        <>
        <QuoteCard data={data} storagequote={storagequote} show_extended_fields={show_extended_fields} location={location} /> 
        </>
        : 
        <>
        <QuoteCard data={data} storagequote={storagequote} show_extended_fields={show_extended_fields} location={location} /> 
        </>
        }
      </Grid>
    </div>
  );
};

QuoteFormOnly.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default QuoteFormOnly;
